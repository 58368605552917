<template>
	<div>
		{{type_monte}} ----
		<div class="form-group">
			<label for="type_monte">{{ $t('monte.type_monte') }} *</label>
			<e-select
				v-model="type_monte_model"
				track-by="tiers_id"
				label="tiers_rs"
				:placeholder="labelTitleTypeMonte"
				:selectedLabel="selectedLabelTypeMonte"
				:options="type_monte"
				:allow-empty="true"
				:show-labels="false"
				:disabled="disabled"
				:loading="loadingTypeMonte"
				:class="{ 'is-invalid': errors && errors.indexOf('cmep') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'ChangementTypeMonte',
		mixins: [],
		props: [
			"contract_id",
			"type_monte",
			"disabled",
			"cmep",
		],
		data () {
			return {
				type_monte_model: null,
				loadingTypeMonte: false,
				errors: [],
				labelTitleTypeMonte: this.getTrad("monte.rechercher_type_monte"),
				selectedLabelTypeMonte: this.getTrad("global.selected_label"),
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.type_monte_model = this.cmep
			},
		},
		watch: {
			type_monte_model(val){
				this.$emit("update:cmep", val)
			},
		},

		components: {
			
		}
	}

</script>